<template>
    <div ref="report" v-bind:class="{'show-executing': loading}" class="report">
        <slot></slot>
    </div>
</template>


<script>

    import $ from 'jquery';
    import Ajax from "../../helpers/ajax";
    import moment from 'moment';

    export default {
        name: 'Report',

        props: {
            url: String,
          
            group: String,

            defParams: Object,
            lazyReport: String
        },

        data () {
            return {
                loading: false,
                params: {},
                defaultFilters: {},
                components: [],
                abortController: null,
                compareData: {}
            }
        },

        created() {
            this.parseParams(this.defParams || {});

            if ((!this.isTrue(this.lazyReport)) || (Object.keys(this.$route.query).length > 0)) {
                this.loadReport();
            }
        },

        watch: {
            '$route'() {
                this.loadReport();
            },
            'changeSelectFilter'(){
                console.log(333)
            }
        },

        updated() {
            this.update();
        },

        methods: {
            isTrue(str) {
                return (str) && (['1', 't', 'true', 'y', 'yes'].indexOf(str) > -1);
            },

            update() {
                this.components = [];

                if (this.$root.$children) {
                  this.$root.$children.forEach((x) => {
                    let name = x.$options.name;
                    switch (name) {
                      case 'Pager':
                        x.emitter.off('changePager')
                        x.emitter.on('changePager', (page, limit) => {
                          this.pageChanged(page, limit);
                        });
                        break;

                      case 'OrderTd':
                        x.emitter.off('changeOrderTd')
                        x.emitter.on('changeOrderTd', (field) => {
                          this.orderChanged(field);
                        });
                        break;

                      case 'InputFilter':
                        this.restoreFilterValue(x);
                        x.emitter.off(`changeInputFilter_${x.name}`)
                        x.emitter.on(`changeInputFilter_${x.name}`, () => {
                          this.applyIntableFilter(x);
                        });
                        break;
                      case 'SelectFilter':
                        this.restoreFilterValue(x);
                        x.emitter.off(`changeSelectFilter_${x.name}`)
                        x.emitter.on(`changeSelectFilter_${x.name}`, () => {
                          this.applyIntableFilter(x);
                        });
                        break;

                      case 'PeriodPickerFilter':
                        this.restoreFilterValue(x);
                        x.emitter.off(`changePeriodPickerFilter_${x.name}`)
                        x.emitter.on(`changePeriodPickerFilter_${x.name}`, () => {
                          this.applyIntableFilter(x);
                        });
                        break;
                    }
                  });
                }
            },


            async reload() {
                this.loadReport(true);
            },

            async exportCSV() {
                let url = `${process.env.VUE_APP_SERVER_URL}/${this.url}/?export=csv&${$.param(this.params)}`;
                window.open(url);
            },

            async loadReport(force = false) {
                this.loading = true;

                if (this.abortController) {
                    this.abortController.abort();
                } else {
                    this.$emit('loading');
                }

                this.abortController = new AbortController();

                let results = [];
                let enabledCompareDate = false;
                let enabledCompareGroup = this.params.filters && this.params.filters.compare_date;

                if (this.params.filters && this.params.filters.compare_date && this.params.group === 'day') {
                    let [ periodName, compareData ] = this.params.filters.compare_date.split('|');
                    let [ compareField ] = compareData.split(';');

                    if (this.params.filters[periodName]) {
                        enabledCompareDate = true;
                        this.params.order = {field: compareField, order: this.params.order.order};
                        this.params.page = 1;
                    }
                }

                results.push(this.loadDefReport(force));

                if (enabledCompareDate || enabledCompareGroup) {
                    results.push(this.loadCompareReport(force));
                }

                let res = await Promise.all(results);
                this.abortController = null;

                let report = res[0];

                if ((res.length === 2 && res[0]) && (res[0].response) && (res[1]) && (res[1].response)) {
                    if (enabledCompareDate) {
                        report.response.rows = this.mergeComparedRows(res[0].response.rows, res[1].response.rows);
                    } else if (enabledCompareGroup) {
                        let source = res[1].response.rows.map(function(row) {
                          if (row.account_id !== undefined) {
                                return {
                                    account_id: row.account_id,
                                    account_title: row.account_title,
                                    group: 'staff',
                                    __compare: row
                                }
                            }

                        });

                        const mergeData = (target, source, prop) => {
                            source.forEach(sourceElement => {
                                let targetElement = target.find(targetElement => {
                                    return sourceElement[prop] === targetElement[prop];
                                });

                                targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
                            })
                        };

                        if ((res.length === 2 && res[0]) && (res[0].response.rows.length > 0) && (res[1]) && (res[1].response.rows.length > 0)) {
                            mergeData(report.response.rows, source, Object.keys(source[0])[0]);
                        }
                    }

                    if (report.response.summary[0]) {
                        report.response.summary[0].__compare = res[1].response.summary[0] || {};
                    }
                }

                this.$emit('done', report);
                this.loading = false;
            },

            mergeComparedRows(rows, comparedRows) {
                rows = rows || [];
                comparedRows = comparedRows || [];

                let result = [],
                    index1 = 0,
                    index2 = 0,
                    funcDate = 'add',
                    stepDate = 'days',
                    d1 = moment(this.compareData.period[0]),
                    d2 = moment(this.compareData.compareStart);

                if (this.params.order.order === 'desc') {
                    funcDate = 'subtract';
                    d1 = moment(this.compareData.period[1]);
                    d2 = moment(this.compareData.compareEnd);
                }

                for (let i = 0; i <= this.compareData.interval; i++) {
                    let d1Format = d1.format('YYYY-MM-DD');
                    let d2Format = d2.format('YYYY-MM-DD');

                    let res = {__compare: {}};
                    res[this.compareData.compareField] = d1Format;
                    res.__compare[this.compareData.compareField] = d2Format;

                    if (rows[index1] && rows[index1][this.compareData.compareField] === d1Format) {
                        res = rows[index1];
                        res.__compare = {};
                        res.__compare[this.compareData.compareField] = d2Format;
                        index1 = index1 + 1;
                    }

                    if (comparedRows[index2] && comparedRows[index2][this.compareData.compareField] === d2Format) {
                        res.__compare = comparedRows[index2];
                        index2 = index2 + 1;
                    }

                    result.push(res);

                    d1 = d1[funcDate](1, stepDate);
                    d2 = d2[funcDate](1, stepDate);
                }

                return result;
            },

            async loadCompareReport(force) {
                let params = $.extend(true, {}, this.params);

                let [ periodName, compareData ] = params.filters.compare_date.split('|');
                let [ compareField, compareStart ] = compareData.split(';');

                let periodFilter = params.filters[periodName].split('|');
                let period = periodFilter[1].split(';');
                let interval = moment(period[1]).diff(period[0], 'days');

                let boolAddTime = (period[0].indexOf(':') > -1);
                let compareEnd = moment(compareStart).add(interval, 'days').format(boolAddTime ? 'YYYY-MM-DD 23:59:59' : 'YYYY-MM-DD');

                this.compareData = { periodName, compareField, compareStart, compareEnd, period, interval};

                params.filters[periodName] = `bw|${compareStart};${compareEnd}`;

                delete params.filters.compare_date;

                return await Ajax.report(this.url, params, this.abortController.signal, force);
            },

            async loadDefReport(force) {

                let currentRoute = this.$route.path;

                for (const item of ['account_integration']) {
                    if (this.$cookies.get(currentRoute + '.' + item)) {
                        this.params.filters[item] = 'eq|' + this.$cookies.get(currentRoute + '.' + item)
                    }
                }

                let params = $.extend(true, {}, this.params);
                if (params.filters) {
                    delete params.filters.compare_date;
                }
                let report = await Ajax.report(this.url, params, this.abortController.signal, force);

                if (report !== null) {

                    if (report.response) {
                        this.updateReport(report.response);
                    }

                    return report;
                }

            },

            updateReport(report) {
                this.$nextTick(() => {

                  if (this.$root.$children) {
                    this.$root.$children.forEach((x) => {
                      let name = x.$options.name;
                      if (name === 'Pager') {
                        x.currentPage = this.params.page;
                        x.maxPage = report.page.max;
                        x.limit = report.page.limit;
                      } else if (name === 'OrderTd') {
                        if (x.field === this.params.order.field) {
                          x.sort = this.params.order.order;
                        } else {
                          x.sort = '';
                        }
                      }
                    });
                  }

                });
            },

            getFilter(name) {
                if (!this.params.filters[name]) {
                    return null;
                }

                let [type, val] = this.params.filters[name].split('|');

                return {
                    type: type,
                    value: val
                }
            },

            applyIntableFilter(component) {
                let filters = {};
                filters[component.name] = this.makeFilter(component);

                this.$root.$children.forEach((x) => {
                      const name = x.$options.name;
                      if (x !== component && name === 'InputFilter') {
                          filters[x.name] = this.makeFilter(x);
                      }
                });

                this.changeReportParams({filters: filters, page: 1});
            },

            changeReportParams(params, clearAll = false) {
                if (clearAll) {
                    this.params = {};
                }
                let query = $.extend(true, this.params, params);

                let result = {};
                $.each(query, (k, v) => {
                    if (v) {
                        result[k] = v;
                    }
                });

                if (query.filters) {
                    result.filters = {};

                    $.each(query.filters, (k, v) => {
                        if (v) {
                            result.filters[k] = v;
                        }
                    });
                }

                result.filters = $.extend(true, {}, this.defaultFilters, result.filters);
                this.params = result;

                let str = this.serializeParams(this.params);
                if (JSON.stringify(str) !== JSON.stringify(this.$route.query)) {
                    this.$router.push({path: this.$route.path, query: str});
                }
            },

            orderChanged(field) {
                this.params.order = this.params.order || {};

                let order = {field: field, order: 'desc'};
                if (this.params.order.field === field) {
                    order.order = (this.params.order.order === 'asc') ? 'desc' : 'asc';
                }

                this.changeReportParams({order: order});
            },

            pageChanged(page, limit) {
                let p = {};

                if (this.params.page !== page) {
                    p.page = page;
                }

                if (this.params.limit !== limit) {
                    p.limit = limit;
                }

                this.changeReportParams(p);
            },

            makeFilter(component) {
                let val = '' + component.getValue();
                let type = component.getFilterType() || 'eq';

                if (Array.isArray(val)) {
                    val = val.join(',');
                } else if (['in', 'not_in', 'inset'].indexOf(type) > -1) {
                    val = val.split(',').map(x => x.trim()).filter(x => !!x).join(',');
                }

                if (val.trim()) {
                    return type + '|' + val;
                } else {
                    return null;
                }
            },

            parseParams(defParams = {}) {
                this.params = $.extend(true, {filters: {}, order: {}, page: 1, limit: 100}, defParams);

                this.defaultFilters = {};
                for(let key in this.params.filters) {
                    this.defaultFilters[key] = this.params.filters[key];
                }

                $.each(this.$route.query, (k, v) => {
                    if (!/^(filters|order)\[/.test(k)) {
                        this.params[k] = v;
                        return;
                    }

                    let m = k.match(/^(.*?)\[(.*?)\]$/);
                    if (m && m.length > 2) {
                        let key = m[1];
                        k = m[2];

                        this.params[key][k] = v;
                    }
                });
            },


            resetFilters() {
                this.$root.$children.forEach((x) => {
                    if (this._isFilterComponent(x) && x.clear) {
                        x.clear();
                    }
                });

                this.params = { filters: {}, order: {} };
                this.changeReportParams({ filters: {}, order: {} });
            },

            _isFilterComponent(x) {
                return ['Pager', 'OrderTd', 'InputFilter', 'SelectFilter', 'PeriodPickerFilter'].includes(x.$options.name);
            },

            restoreFilterValue(component) {
                let filter = this.getFilter(component.name);
                if (filter) {
                    let val = this.getFilter(component.name).value;
                    component.setValue(val);
                }
            },

            serializeParams(params, result = {}, prefix = null) {
                $.each(params, (k, v) => {
                    if (prefix) {
                        k = `${prefix}[${k}]`;
                    }

                    if (typeof v !== 'object') {
                        result[k] = `${v}`;
                        return;
                    }

                    this.serializeParams(v, result, k);
                });

                return result;
            },

            getFilters() {
                return this.params.filters
            }
        }
    }
</script>
