import { createApp } from 'vue'

import App from './pages/App';
import routes from './routes';

import VueCookies from 'vue-cookies'
import VueSocketIO from 'vue-3-socket.io'
import VueNotification from "@kugatsu/vue3-toast";
import sockeio from "socket.io-client";
import mitt from 'mitt';
import mask from 'vue-the-mask'

const emitter = mitt();
const io = new VueSocketIO({
  debug: false,
  connection: sockeio(`${window.location.protocol}//${window.location.hostname.replace('app', 'work').replace('partner', 'work')}${window.location.port >= 8080 ? ':3000' : ''}`, { transports: ["websocket"] })
})

if (!process.env.VUE_APP_SERVER_URL) {
  throw new Error('VUE_APP_SERVER_URL is undefined');
}
if (!process.env.VUE_APP_IMAGE_ADDRESS) {
  throw new Error('VUE_APP_IMAGE_ADDRESS is undefined');
}

routes().then((router) => {
  global.router = router;
  const app = createApp(App)
  app.use(router)
  app.use(VueCookies)
  app.use(VueNotification, {
    duration: 3000,
    position: "top-right",
    fullSize: false,
    fullSizeMobile: true,
    colors: {
      new: "bg-blue-500",
      success: "bg-green-500",
      warning: "bg-yellow-500",
      error: "bg-red-500",
    },
  })
  app.use(io)
  app.use(mask)
  app.config.globalProperties.emitter = emitter;
  app.config.globalProperties.domain = process.env.VUE_APP_IMAGE_ADDRESS;

  app.mount('#app');

});
