<template>
  <div>
    <h1>Профиль</h1>

    <router-link class="btn btn-primary" to="/setting/account">Настройки профиля</router-link>
   <div>

   </div>
    <Nav />

  </div>
</template>

<script>
import mixins from '../../../helpers/mixins.js';
import Nav from "@/pages/partner/nav";

export default {
  mixins: [mixins],

  components: {
    Nav,
  },

  created() {

  },

  data() {
    return {
      data: {
        profile: {
          plan: {
            current_other: 0,
            plan_other: 0,
            current_excursion: 0,
            plan_excursion: 0
          },
          schedule: []
        },



        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        places: [],
      },
      days: ['', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],

      order: {
        excursion_id: null,
      },

      times: false,
      activeTime: false,


      orderStep: 1,
      orderPhoneCount: 1,
      orderStepTitle: [
        '',
        'Общая информация',
        'Информация о клиенте',
        'Оплата и бронирование'
      ],
      error: null,
      report: null
    }
  },

  methods: {



  }
}
</script>
