<template>
    <div class="input" v-bind:class="{ error: !!selectError }">
      <label v-bind:for="id" class="input-placeholder">{{text}}</label>
        <select
            v-bind:name="name"
            v-bind:id="id"
            v-bind:class="{ 'empty': isEmpty, 'required': isRequired }"
            v-model="selectValue"
            v-on:change="$emit('update:modelValue', selectValue)"
            :disabled="isDisabled"
        >
            <option v-if="isTrue(addEmpty)"></option>
            <option v-for="option in options" v-bind:key="option[trackBy]" v-bind:value="option[trackBy]">
                {{ option[label] }}
            </option>
        </select>
        <div class="msg-error" v-if="selectError">{{selectError}}</div>
    </div>
</template>

<script>
    export default {
        name: 'Select',

        props: {
          modelValue: {
            default: ''
          },
            def: {
              type: String,
              default: ''
            },
            name: String,
            text: String,
            value: {},
            required: String,
            disabled: String,
            options: {
                type: Array,
                default: () => []
            },
            trackBy: {
                type: String,
                default: 'id'
            },
            label: {
                type: String,
                default: 'label'
            },

            filterType: {
                type: String,
                default: 'eq'
            },

            addEmpty: String,
        },

        watch: {
          modelValue() {
                this.selectValue = this.modelValue;
                this.$emit('update:modelValue', this.selectValue)
            }

        },

        computed: {
            isEmpty() {
                return !this.selectValue;
            }
        },

      created() {
        if (!this.$root.$children) {
          this.$root.$children = [this];
        } else {
          this.$root.$children.map((i, k) => {
            if (i.name === this.name) {
              this.$root.$children.splice(k, 1)
            }
          })
          this.$root.$children.push(this);
        }
      },

        data () {
            return {
                id: `select_${Date.now()}_${Math.round(Math.random() * 99999)}`,
                selectValue: this.modelValue || (this.def || ''),
                selectError: '',
                isRequired: this.isTrue(this.required),
                isDisabled: this.isTrue(this.disabled),
                filterTypeValue: this.filterType,
            };
        },

        methods: {
            clear() {
                this.selectValue = '';
            },

            getValue() {
                return this.selectValue;
            },

            setValue(val) {
                this.selectValue = val;
            },

            setError(val) {
                this.selectError = val;
            },

            clearError() {
                this.selectError = '';
            },

            getFilterType() {
                return this.filterTypeValue;
            },

            isTrue(str) {
                return (str) && (['1', 't', 'true', 'y', 'yes'].indexOf(str) > -1);
            }
        }
    }
</script>