<template>
  <div>
    <h1>Настройки профиля</h1>

    <h2>Изменить пароль</h2>

    <div class="flex flex-g2">
      <Input name="user_password" text="Новый пароль" type="password" required="true" v-model.trim="data.user_password" />
      <Input name="confirm_password" text="Новый пароль ещё раз" type="password" required="true" v-model.trim="data.confirm_password" />
    </div>

    <div id="policy">
      <button type="submit" class="btn btn-primary" v-on:click="save">Сохранить</button>
    </div>
    <partnerNav />
  </div>
</template>

<script>
import mixins from '@/helpers/mixins.js';
import partnerNav from "@/pages/partner/nav";
import User from '@/user';
import Ajax from "@/helpers/ajax";
import Input from '@/components/Input';

export default {
  mixins: [mixins],

  components: {
    partnerNav,
    Input
  },

  data() {
    return {
      user: User.currentUser || {},
      data: {
        user_password: null,
        confirm_password: null
      }
    }
  },

  methods: {
    async save() {
      this.clearError();

      if (this.data.confirm_password !== this.data.user_password) {
        this.setError({
          confirm_password: 'Пароли не совпадают',
          user_password: 'Пароли не совпадают'
        });

        return false;
      }

      let data = {}

      if (this.data.confirm_password && this.data.user_password) {
        data.user_password = this.data.user_password
        data.confirm_password = this.data.confirm_password
      } else {
        return false;
      }

      let list = [];
      list.push(Ajax.post(`/account/save/` + this.user.account.account_id, data));

      let [userResult] = await Promise.all(list);

      if (userResult.error) {
        this.setError(userResult.error);
      }

      if (userResult.response) {

        User.loadCurrentUser()

        this.user = User.currentUser;

        this.$toast.success({content: `Пароль успешно изменён`});

      }

    }
  }


}
</script>