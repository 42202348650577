<template>
  <div id="app" v-bind:class="{ 'left-nav-on': loggedIn, 'impersonate-app': isImpersonate }">
    <VueNotificationContainer class="toast"/>
    <template v-if="loggedIn">

      <section class="main">
          <router-view></router-view>
      </section>

    </template>
    <template v-else>
      <router-view></router-view>

    </template>

  </div>
</template>

<script>
  import User from '../user';
  import VueNotificationContainer from "@kugatsu/vue3-toast/VueNotificationContainer";

  export default {
    components: {
      VueNotificationContainer
    },

    data () {
      return {
        loggedIn: User.isLoggedIn(),
        isImpersonate: User.isImpersonate(),
        role: ''
      }
    },

    created () {
      User.on('change.app', () => {
        this.updateUser();
      });

      User.on('refresh.app', () => {
        if (this.$route.path !== User.getHomepage()) {
          this.$router.replace(User.getHomepage());
        }

        this.updateUser();

      });

      this.updateUser();

      this.sockets.unsubscribe('updateScheduleReport')
      this.sockets.subscribe('updateScheduleReport', () => {
        this.emitter.emit('updateScheduleReport')
      })
    },

    methods: {
      updateUser() {
       this.loggedIn = User.isLoggedIn();
       this.isImpersonate = User.isImpersonate();
       this.role = (this.loggedIn) ? User.currentUser.user.role_type : '';
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/common";
</style>