<template>
        <div class="filter-row-input icon-date">
            <input type="text" ref="container" v-model="dateValue" readonly :placeholder="placeholder">
            <input type="hidden" ref="hidden" v-bind:name="name" v-model="inputValue" data-type="bw">
            <div class="clear-input"></div>
            <div class="input-icon rui rui-picker"></div>
        </div>
</template>


<script>
import $ from 'jquery';
import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import daterangepicker from 'daterangepicker';

export default {
  name: 'PeriodPickerFilter',

  props: {
    placeholder: {
      default: ''
    },
    modelValue: {
      default: ''
    },
    name: String,
    addTime: String,
    allowFuture: {type: Boolean, default: false},
    value: {}
  },

  emits: ['update:modelValue'],

  computed: {
    isEmpty() {
      return !this.dateValue;
    }
  },

  data() {
    return {
      dateValue: '',
      inputValue: this.value || '',
      boolAddTime: (this.addTime) && (['1', 't', 'true', 'y', 'yes'].indexOf(this.addTime) > -1),
    }
  },
  watch: {
    inputValue() {
      this.$emit('update:modelValue', this.inputValue)
    }
  },

  created() {
    if (!this.$root.$children) {
      this.$root.$children = [this];

    } else {
      this.$root.$children.push(this);
    }
  },

  mounted() {
    let params = {
      autoApply: false,
      autoUpdateInput: false,
      ranges: {
        'Вчера': [moment.utc().subtract(1, 'days'), moment.utc().subtract(1, 'days')],
        'Сегодня': [moment.utc(), moment.utc()],
        'Завтра': [moment.utc().add(1, 'days'), moment.utc().add(1, 'days')],
        'Послезавтра': [moment.utc().add(2, 'days'), moment.utc().add(2, 'days')],
      },
      locale: {
        cancelLabel: 'Очистить',
        applyLabel: 'Применить',
      },
      "linkedCalendars": false,
      "showCustomRangeLabel": false,
      "alwaysShowCalendars": true,
    };

    if (!this.allowFuture) {
      params.maxDate = new Date();
    }

    if (this.inputValue) {
      let resultFormat = (this.boolAddTime) ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
      let d = this.inputValue.split(';');

      params.startDate = moment(d[0], resultFormat).format('DD.MM.YYYY');
      params.endDate = moment(d[1], resultFormat).format('DD.MM.YYYY');

      this.dateValue = `${params.startDate} - ${params.endDate}`;
    }

    $(this.$refs.container).daterangepicker(params);

    $(this.$refs.container).on('cancel.daterangepicker', () => {
      this.inputValue = '';
      this.dateValue = '';
      this.emitter.emit(`changePeriodPickerFilter_${this.name}`, this.inputValue);
    });

    $(this.$refs.container).on('apply.daterangepicker', (ev, picker) => {
      this.dateValue = `${picker.startDate.format('DD.MM.YYYY')} - ${picker.endDate.format('DD.MM.YYYY')}`;

      this.inputValue = `${picker.startDate.format('YYYY-MM-DD 00:00:00')};${picker.endDate.format('YYYY-MM-DD 23:59:59')}`;

      this.emitter.emit(`changePeriodPickerFilter_${this.name}`, this.inputValue);
    });

  },

  methods: {
    clear() {
      this.inputValue = '';
      this.dateValue = '';

      let now = new Date();

      $(this.$refs.container).data('daterangepicker').setStartDate(now);
      $(this.$refs.container).data('daterangepicker').setEndDate(now);

      this.emitter.emit(`changePeriodPickerFilter_${this.name}`, this.inputValue);
    },

    getValue() {
      return this.inputValue;
    },

    setValue(val) {
      if (!val) {
        this.inputValue = '';
        return;
      }
      let resultFormat = (this.boolAddTime) ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
      let d = val.split(';');

      let startDate = moment(d[0], resultFormat).format('DD.MM.YYYY');
      let endDate = moment(d[1], resultFormat).format('DD.MM.YYYY');


      this.inputValue = val;
      this.dateValue = `${startDate} - ${endDate}`;

      let target = $(this.$refs.container)
      if (target && target.data('daterangepicker') && target.data('daterangepicker').setStartDate) {
        target.data('daterangepicker').setStartDate(startDate);
        target.data('daterangepicker').setEndDate(endDate);
      }
    },

    getFilterType() {
      return 'bw';
    }
  },

  beforeDestroy() {
    $(this.$refs.container).off('.daterangepicker');
  }
}
</script>
